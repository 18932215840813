.App {
  text-align: center;
  font-family: revert;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.CatContainer {
  display: flex;
  flex-direction: column;
}

.CatItem {
  padding-left: 10px;
}
.MenuItem_wrap > nav {
  display: inline;
  margin-right: 20px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Cart {
  margin-left: 10px;
  width: 40px;
}

.M3Badge {
  position: relative;
  left: -27px !important;
  top: -8px !important;
}
.checkedOutOrderNumber {
  padding: 10px;
  margin-top: 15px;
  font-size: 20px;
}
.checkedOutOrderNumber span {
  font-weight: bold;
  color: cornflowerblue;
}
.copyRight_Wrap {
  margin-top: 40px !important;
  text-align: center;
  font-size: 14px;
}
.modal-content {
  font-family: revert;
}
.about_Wraper * {
  float: left;
}
.elem-wrap {
  margin-bottom: 10px;
}
.elem-header {
  margin-bottom: 20px;
  color: mediumaquamarine;
  width: 100%;
}
.elem-content {
  margin-bottom: 40px;
  text-align: left;
}
.navbar-brand {
  padding-left: 5px;
}
.SKNSIb {
  text-align: left;
  width: 100px;
}
.carousel-item > img {
  max-height: 600px;
}
#orderDetailTitle {
  color: rosybrown;
}
.operationMode {
  margin-bottom: 20px;
  font-size: 30px;
  color: red;
}
.errorMsg {
  color: red;
  margin-left: 5px;
}
.active {
  font-weight: bolder;
}
.sq-card-iframe-container {
  height: 48px !important;
}
